import React from "react"
import Layout from "../components/layout"
import Subscribe from "../components/subscribe"
import SEO from "../components/seo"

const styles = require('../styles/signup.module.css')
const classNames = require('classname')

const SignUpPage: React.FC = () => (
    <Layout>
        <SEO title="Sign Up"/>
        <div className={styles.container}>
                <div className={classNames({ [styles.main]: true, [styles.mainRaised]: true })}>
                    <div className={styles.blueBanner}>
                    <div className={styles.space150}></div>
                      <div className={styles.header}>
                        <h1 className={styles.statement}>Sign Up</h1>
                        
                    </div>  
                    <div className={styles.space150}></div>
                    </div>
                    
                    <div className={styles.space100}></div>
                    <Subscribe/>
                    <div className={styles.space100}></div>
                    </div>
                    
                    </div>
    </Layout>
)

export default SignUpPage